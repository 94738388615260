.multi-select .css-13cymwt-control {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
}
.multi-select .css-t3ipsp-control {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    min-height: calc(1.5em + 1.65rem + 2px);
    padding: 0.825rem 1.5rem !important;
    font-size: 1.15rem;
    border-radius: 0.625rem;
}

.multi-select .css-1nmdiq5-menu > *{
    background-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
}

.multi-select > div > input {
   margin: 0px;
}

.comments-lists{
    height: 338px;
    overflow: auto;
}