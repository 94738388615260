.popup-card-container {
    position: fixed;
    top: 0;
    left: 192px;
    width: 76%;
    height: 83%;
    /* left: 0;
    width: 100%;
    height: 100%; */
    /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the popup appears above other elements */
  }
  
  .popup-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-icon {
     position: absolute; 
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ededed;
    border: 1px solid #999;
    line-height: 25px;
    text-align: center;
    font-size: 16px; 
    margin-top: 10px;
  }
  