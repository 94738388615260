.document-height{
    height:75px;
}

.document-name-max-height{
    max-height:20px;
}

.document-name-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}